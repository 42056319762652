import adminRouteMap from "./adminRouteMap";
import { AdminForgot, AdminLogin, AdminReset } from "../../pages";

export default function route() {
  return [
    {
      path: adminRouteMap.LOGIN.path,
      name: "Admin Login",
      element: <AdminLogin />,
      private: false,
    },
    {
      path: adminRouteMap.ADMINFORGOT.path,
      name: "Admin Forgot",
      element: <AdminForgot />,
      private: false,
    },
    {
      path: `${adminRouteMap.ADMINRESET.path}/:token`,
      name: "Admin Reset",
      element: <AdminReset />,
      private: false,
    },
  ];
}
