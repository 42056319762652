import adminRouteMap from "./adminRouteMap";
import {
  AdminDashboard,
  AdminProfile,
  AdminUser,
  AdminUserDetails,
  AdminReview,
  AdminCms,
  AdminFaq,
  AdminSupport,
  AdminFunding,
  AdminBlog,
} from "../../pages";

export default function route() {
  return [
    {
      path: adminRouteMap.DASHBOARD.path,
      name: "Admin Dashboard",
      element: <AdminDashboard />,
      private: true,
    },
    {
      path: adminRouteMap.ADMINPROFILE.path,
      name: "Admin Profile",
      element: <AdminProfile />,
      private: true,
    },
    {
      path: adminRouteMap.ADMINUSER.path,
      name: "Admin User",
      element: <AdminUser />,
      private: true,
    },
    {
      path: `${adminRouteMap.ADMINUSERDETAILS.path}/:id`,
      name: "Admin User Details",
      element: <AdminUserDetails />,
      private: true,
    },
    {
      path: adminRouteMap.ADMINREVIEW.path,
      name: "Admin Review",
      element: <AdminReview />,
      private: true,
    },
    {
      path: adminRouteMap.ADMINCMS.path,
      name: "Admin CMS",
      element: <AdminCms />,
      private: true,
    },
    {
      path: adminRouteMap.ADMINFAQ.path,
      name: "Admin FAQ",
      element: <AdminFaq />,
      private: true,
    },
    {
      path: adminRouteMap.ADMINSUPPORT.path,
      name: "Admin Support",
      element: <AdminSupport />,
      private: true,
    },
    {
      path: adminRouteMap.ADMINFUNDING.path,
      name: "Admin Funding",
      element: <AdminFunding />,
      private: true,
    },
    {
      path: adminRouteMap.ADMINBLOGS.path,
      name: "Admin Blogs",
      element: <AdminBlog />,
      private: true,
    },
  ];
}
